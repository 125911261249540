/* Общие переменные */
:root {
    --font-family: 'Inter', sans-serif;
    --font-size-base: 16px;
    --border-radius: 10px;

    /* Цвета по умолчанию (темная тема) */
    --color-background: #1b1d20;
    --color-background-card: #23292d;
    --color-background-footer: #191b1e;
    --color-background-bot-card: #283133;
    --color-text: #eef0f0;
    --color-accent: #8f66f8;
    --color-accent-secondary: #212b19;
    --color-goal-inactive: rgba(255, 255, 255, 0.2);
    --color-border: rgba(255, 255, 255, 0.1);

    /* Кнопки */
    --color-button-primary: #212b19;
    --color-button-secondary: #8f66f8;

    /* Тени */
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Светлая тема с использованием media queries */
@media (prefers-color-scheme: light) {
    :root {
        --color-background: #f8fafb;
        --color-background-card: #f6f6fb;
        --color-background-footer: #a5a7d8;
        --color-background-bot-card: #ebecfa;
        --color-text: #30303d;
        --color-accent: #4d4e86;
        --color-accent-secondary: #efaca7;
        --color-goal-inactive: rgba(150, 150, 150, 0.3);
        --color-border: #a5a7d8;

        /* Кнопки */
        --color-button-primary: #7f81c8;
        --color-button-secondary: #facdb1;

        /* Тени */
        --box-shadow: 0 2px 4px rgba(127, 129, 200, 0.2);
    }
}

/* Основные стили */
body {
    font-family: var(--font-family);
    background-color: var(--color-background);
    color: var(--color-text);
    font-size: var(--font-size-base);
}

.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.card {
    background-color: var(--color-background-card);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
}

.button-primary {
    background-color: var(--color-button-primary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

.button-secondary {
    background-color: var(--color-button-secondary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

.goal-active {
    background-color: var(--color-accent);
    color: white;
}

.goal-inactive {
    background-color: var(--color-goal-inactive);
    color: #888;
}

.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-background-footer);
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-top: 1px solid var(--color-border);
}

.navbar-item {
    font-size: 0.9em;
    color: var(--color-text);
    text-align: center;
}

.navbar-item.active {
    color: var(--color-accent);
}

/* Стили для элементов текста и заголовков */
h1, h2, h3 {
    color: var(--color-text);
}

p {
    color: var(--color-text);
}

/* Стили для навигационной панели */
.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-background-footer);
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-top: 1px solid var(--color-border);
    box-shadow: var(--box-shadow);
    z-index: 10;
}

.navbar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-text);
    text-decoration: none;
    font-size: 14px;
}

.navbar-item.active {
    color: var(--color-accent);
}

.navbar-item p {
    margin: 0;
    font-size: 0.9em;
    color: inherit;
}

.sobriety-counter-container {
    padding: 20px;
    text-align: center;
    background-color: var(--color-background);
    color: var(--color-text);
    border-radius: var(--border-radius);
    max-width: 400px;
    margin: 20px auto;
}

.sobriety-title {
    font-size: 1.4em;
    margin-bottom: 20px;
}

.time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.time-box {
    background-color: var(--color-accent-secondary);
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 1.2em;
    min-width: 200px;
}

.time-value {
    font-weight: bold;
    font-size: 1.5em;
}

.time-label {
    font-size: 1em;
}

.reset-button {
    padding: 10px 15px;
    margin-top: 20px;
    font-size: 1em;
    color: white;
    background-color: #ff6b6b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

.goals-title {
    margin-top: 30px;
    font-size: 1.3em;
    font-weight: bold;
}

.goals-container,
.custom-goals-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.goal-box {
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 1em;
    cursor: pointer;
}

.goal-select {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.chat-container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: var(--font-family);
}

.chat-header {
    text-align: center;
    color: var(--color-text);
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: bold;
}

.chat-messages-container {
    height: 400px;
    overflow-y: auto;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--color-background-card);
    border: 1px solid var(--color-border);
    margin-bottom: 10px;
}

.chat-message {
    max-width: 95%;
    padding: 10px 15px;
    border-radius: 20px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 1em;
    box-shadow: var(--box-shadow);
    width: fit-content;
    text-align: left;
}

.chat-user-message {
    background-color: var(--color-accent);
    color: white;
    align-self: flex-end;
    border-top-right-radius: 5px;
    margin-left: auto;
}

.chat-bot-message {
    background-color: var(--color-background-bot-card);
    color: var(--color-text);
    align-self: flex-start;
    border-top-left-radius: 5px;
}

.chat-input-container {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-border);
    padding: 10px 0;
}

.chat-input {
    flex-grow: 1;
    padding: 10px;
    font-size: 1em;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    outline: none;
    margin-right: 10px;
    background-color: var(--color-background-card);
    color: var(--color-text);
}

.chat-send-button {
    padding: 10px 15px;
    font-size: 1.2em;
    color: white;
    background-color: var(--color-accent);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
}

.chat-heading {
    font-size: 1.2em;
    font-weight: bold;
    margin: 5px 0;
    color: var(--color-text);
}

.chat-paragraph {
    font-size: 1em;
    margin: 5px 0;
    color: var(--color-text);
}

.profile-container {
    padding: 20px;
    text-align: center;
}

.profile-header {
    font-size: 2em;
    color: var(--color-text);
}

.home-container {
    padding: 20px;
    text-align: center;
}

.home-header {
    font-size: 2em;
    color: var(--color-text);
}

.home-container {
    padding: 20px;
    text-align: center;
}

.home-header {
    font-size: 2em;
    color: var(--color-text);
    margin-bottom: 20px;
}

/* Прогресс */
.progress-card,
.tip-card,
.goals-card {
    background-color: var(--color-background-card);
    color: var(--color-text);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: var(--box-shadow);
}

.progress-card h2,
.tip-card h2,
.goals-card h2 {
    font-size: 1.3em;
    margin-bottom: 10px;
}

/* Цели */
.goals-card ul {
    list-style-type: none;
    padding: 0;
}

.goals-card .goal {
    padding: 10px;
    border-radius: var(--border-radius);
    margin: 5px 0;
    font-size: 1em;
}

.goal-active {
    background-color: var(--color-accent);
    color: #fff;
}

.goal-inactive {
    background-color: var(--color-goal-inactive);
    color: #888;
}

.journal-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.journal-header {
    font-size: 2em;
    color: var(--color-text);
    text-align: center;
    margin-bottom: 20px;
}

/* Форма добавления записи */
.entry-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.entry-input {
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    font-size: 1em;
    background-color: var(--color-background-card);
    color: var(--color-text);
}

.entry-textarea {
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    font-size: 1em;
    resize: vertical;
    min-height: 100px;
    background-color: var(--color-background-card);
    color: var(--color-text);
}

.add-entry-button, .cancel-entry-button {
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 1em;
    box-shadow: var(--box-shadow);
    margin-bottom: 10px;
}

.add-entry-button {
    background-color: var(--color-button-primary);
    color: white;
}

.cancel-entry-button {
    background-color: #ff6b6b;
    color: white;
}

/* Список записей */
.entries-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.entry-card {
    background-color: var(--color-background-card);
    padding: 15px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.entry-title {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--color-text);
    margin-bottom: 5px;
}

.entry-date {
    font-size: 0.9em;
    color: var(--color-text);
    opacity: 0.8;
    margin-bottom: 10px;
}

.entry-content {
    font-size: 1em;
    color: var(--color-text);
}

.no-entries {
    color: var(--color-text);
    opacity: 0.6;
    text-align: center;
}

.delete-entry-button {
    background-color: #ff6b6b;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 0.9em;
    box-shadow: var(--box-shadow);
    margin-top: 10px;
}

/* Стили для попапа подтверждения удаления */
.delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delete-popup-content {
    background-color: var(--color-background-card);
    padding: 20px;
    border-radius: var(--border-radius);
    text-align: center;
    box-shadow: var(--box-shadow);
    width: 80%;
    max-width: 300px;
}

.confirm-delete-button, .cancel-delete-button {
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 1em;
    margin: 5px;
    color: white;
}

.confirm-delete-button {
    background-color: #ff6b6b;
}

.cancel-delete-button {
    background-color: var(--color-button-secondary);
}

.auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: var(--color-background-card);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.auth-input {
    padding: 10px;
    font-size: 1em;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    background-color: var(--color-background);
    color: var(--color-text);
}

.auth-button {
    padding: 10px 15px;
    background-color: var(--color-button-primary);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    font-size: 1em;
}

.auth-message {
    margin-top: 10px;
    color: var(--color-accent);
    font-size: 0.9em;
}

.toggle-auth-button {
    margin-top: 15px;
    background-color: var(--color-button-secondary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
}

.goal-image {
    max-width: 50px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.profile-container {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.profile-header {
    font-size: 2rem;
    margin-bottom: 20px;
}

.profile-info {
    margin-bottom: 30px;
}

.profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.profile-links ul {
    list-style: none;
    padding: 0;
}

.profile-links li {
    margin-bottom: 10px;
}

.profile-link {
    color: #4f93f3;
    text-decoration: none;
    font-size: 1.2rem;
}

.profile-link:hover {
    text-decoration: underline;
}
